@import '../../../node_modules/musalleen-react-theme/lib/theme/variables';

.view .view-content.view-error {
    position: relative;
    padding: 0;
    .display-404 {
        font-weight: 800;
        text-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
        font-size: 12rem;
        margin-top: -8rem;
    }
    .display-404 > span {
        width: 12rem;
        height: 12rem;
        border-radius: 100%;
        background: $brand-primary;
        display: inline-block;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            width: 88%;
            height: 88%;
            background: #fff;
            border-radius: 100%;
            top: 6%;
            left: 6%;
        }
    }
}
