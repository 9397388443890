.full-width {
    width: 100vw;
    transform: translate(0, 0);
    background-position: center;
    background-size: cover;
    overflow: scroll;
}

.full-width-overlay {
    display: none;
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2;
}
