body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.site-head {
  height: 4.4em; }
  .site-head .right-elems .wrap {
    padding: 0.5rem;
    margin-left: 1.8rem;
    position: relative;
    text-align: end; }
    .site-head .right-elems .wrap .dropdown-item:active {
      background: #f5f5f5;
      color: #212121; }
  .site-head .right-elems .profile {
    padding: 0; }
    .site-head .right-elems .profile img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      border: 2px solid white; }
  .site-head .right-elems .notify .badge {
    font-size: 65%;
    position: absolute;
    top: -4px;
    right: -4px; }
  .site-head .site-search {
    position: relative; }
    .site-head .site-search input {
      font-size: 14px;
      border: none;
      border-radius: 1px;
      padding-left: 3.5rem;
      transition: 0.2s ease-in;
      padding-top: 0.9rem;
      padding-bottom: 0.9rem; }
    .site-head .site-search > svg {
      position: absolute;
      top: 0.8rem;
      left: 1rem; }
  .site-head .masjid-name {
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
    word-break: break-all; }

@media (orientation: portrait) {
  .site-head {
    padding-top: 30px !important; } }

.links {
  text-decoration: none;
  color: white; }

@media (orientation: portrait) {
  .view-header {
    padding-top: 30px !important; }
  .title {
    word-break: break-all; } }

.full-width {
  width: 100vw;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  background-position: center;
  background-size: cover;
  overflow: scroll; }

.full-width-overlay {
  display: none;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2; }

.view .view-content.view-error {
  position: relative;
  padding: 0; }
  .view .view-content.view-error .display-404 {
    font-weight: 800;
    text-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    font-size: 12rem;
    margin-top: -8rem; }
  .view .view-content.view-error .display-404 > span {
    width: 12rem;
    height: 12rem;
    border-radius: 100%;
    background: #075e55;
    display: inline-block;
    position: relative; }
    .view .view-content.view-error .display-404 > span:before {
      content: '';
      position: absolute;
      width: 88%;
      height: 88%;
      background: #fff;
      border-radius: 100%;
      top: 6%;
      left: 6%; }

.widget-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.processing-payment-container {
  pointer-events: none; }

.view .view-content.view-error {
  position: relative;
  padding: 0; }
  .view .view-content.view-error .display-404 {
    font-weight: 800;
    text-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    font-size: 12rem;
    margin-top: -8rem; }
  .view .view-content.view-error .display-404 > span {
    width: 12rem;
    height: 12rem;
    border-radius: 100%;
    background: #075e55;
    display: inline-block;
    position: relative; }
    .view .view-content.view-error .display-404 > span:before {
      content: '';
      position: absolute;
      width: 88%;
      height: 88%;
      background: #fff;
      border-radius: 100%;
      top: 6%;
      left: 6%; }

.float-to-bottom {
  position: fixed;
  bottom: 2%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

